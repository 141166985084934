.custom-branding .custom-no-margin {
  padding-right: 0;
  padding-left: 0;
  overflow-x: hidden;
}
.custom-branding .branding-heading {
  color: white;
}
.custom-branding .branding-inner:hover .branding-heading {
  color: #ffc192;
}
