.explainer-video .highlight-box {
  height: 160px;
  max-width: 45%;
  width: 100%;
  display: block;
  object-fit: cover;
}

.explainer-video .video-style {
  position: absolute;
  left: 10%;
  height: auto;
  width: 100%;
  top: 25%;
  max-width: 85%;
}

.explainer-video .play-button {
  cursor: pointer;
  z-index: 1;

  transition: opacity 0.3s ease-in-out; /* Smooth transition */
  opacity: 1;
}

.explainer-video .play-button.hidden {
  /* display: none; */
  /* Add this styling to ensure the video plays smoothly. */
  opacity: 0;
  pointer-events: none;
}

@media screen and (max-width: 1023px) {
  .explainer-video .highlight-box {
    display: none !important;
  }

  .explainer-video .video-style {
    position: relative !important;
    left: 0;
    top: 0;
  }

  .explainer-video .play-button {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -70%);
  }
}

@media screen and (min-width: 1024px) {
  .explainer-video .explainer-content {
    position: relative;
    top: 70px;
  }
}

.global-light-btn:hover {
  animation: rotate624 0.7s ease-in-out both;
}

@keyframes rotate624 {
  0% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }

  25% {
    transform: rotate(3deg) translate3d(0, 0, 0);
  }

  50% {
    transform: rotate(-3deg) translate3d(0, 0, 0);
  }

  75% {
    transform: rotate(1deg) translate3d(0, 0, 0);
  }

  100% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }
}
