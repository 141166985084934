.marketing-services .service-inner {
  background-color: #3dadea;
  position: relative;
  border-radius: 20px;
}
@media screen and (min-width: 1024px) {
  .marketing-services .second-column {
    transform: translateY(10%);
    margin-bottom: 150px;
  }
}

.marketing-services .service-inner .service-image {
  position: relative;
  left: -5px;
  bottom: 5px;
  width: 100%;
  height: auto;
}

.marketing-services .service-main .service-heading,
.marketing-services .service-main .service-description {
  color: #ffffff;
}

.marketing-services .service-main .service-circle {
  height: 25px;
  width: 25px;
  border: 1px solid #343131;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}

.marketing-services .service-main:hover .service-heading {
  color: #ffc192;
}

.marketing-services .service-main:hover .service-inner {
  background-color: #ffc192;
}

.marketing-services .service-main:hover .service-circle {
  border: 1px solid #ffc192;
}

.marketing-services .service-main:hover .service-circle .tick-icon {
  content: url("../assets/images/serciceArrow.svg");
}
