.happy-clients {
  background: linear-gradient(
    to bottom,
    #000000 20%,
    #4d1903 72%,
    #9d3502 100%
  );
}

@media screen and (min-width: 1024px) {
  .happy-clients .play-button {
    margin-left: 30%;
  }

  .happy-clients .video-main {
    margin: 75px 0;
  }

  .happy-clients .highlight-box {
    height: auto;
    width: 100%;
    max-width: 350px;
  }

  .happy-clients .video-style {
    position: absolute;
    top: 35%;
    left: 15%;
    width: 80%;
    height: auto;
  }

  .happy-clients .play-div {
    position: absolute;
    top: 80%;
    left: 15%;
    width: 80%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .happy-clients .play-icon {
    max-width: 50px;
    max-height: 50px;
    cursor: pointer;
  }
}

@media screen and (max-width: 1023px) {
  .happy-clients .highlight-box {
    display: none;
  }

  .happy-clients .video-style {
    height: auto;
    width: 100%;
  }

  .happy-clients .play-div {
    position: absolute;
    top: 50%;
    left: 50%;
    height: auto;
    transform: translate(-50%, -50%);
  }

  .happy-clients .video-main {
    margin: 10px 0;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
  .happy-clients .play-div {
    top: 85% !important;
  }

  .happy-clients .highlight-box {
    height: 150px !important;
  }
}
