.navbar {
  padding: 0 !important;
  color: #2c2d30 !important;
}

.nav-link-active {
  color: #e77817 !important;
}

.navbar-toggler-icon {
  background-image: url("../assets/images/headerIcon.png");
  background-repeat: no-repeat;
  background-position: center;
}

.navbar-toggler {
  padding: 2px !important;
  transition: none !important;
  border: none !important;
}

.navbar-toggler:focus,
.btn-close:focus {
  box-shadow: none !important;
}

@media screen and (min-width: 1024px) {
  .custom-dropdown {
    background-color: rgba(52, 49, 49, 0.9) !important;
    width: 100vw !important;
    height: 100vh !important;
    position: fixed !important;
    top: 45px !important;
    left: 0 !important;
    overflow-y: auto !important;
    z-index: 1 !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
  }
}

@media screen and (max-width: 540px) {
  .navbar .nav-link {
    color: #2c2d30 !important;
  }
  .navbar .dropdown-item {
    color: #2c2d30 !important;
  }
}
