.popup-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  padding: 15px;
  max-width: 540px;
  border-radius: 50px;
  color: #343131;
  border: 2px solid #e77817;
  background-color: #ffffff;
  font-weight: 500;
}

.popup-container .popup-para {
  font-size: 11px;
}

.popup-content {
  padding-left: 45px;
  padding-right: 45px;
  border-radius: 50px;
}

.popup-container .popup-btn {
  background-color: #ffc192;
  border-radius: 10px;
  width: fit-content;
  color: white;
  padding: 10px 50px;
  border: none;
  font-size: 11px;
  color: #010101;
}

.popup-container .close-popup {
  cursor: pointer;
}

.popup-container label {
  font-size: 13px;
}

.popup-container input,
.popup-container textarea {
  outline: none;
  border: 1px solid #ffc192;
  background-color: #f9fafc;
  padding: 8px;
  border-radius: 5px;
  font-size: 14px;
  width: 100%;
}

.popup-container input,
textarea:focus {
  outline: none;
}

@media screen and (max-width: 768px) {
  .popup-content {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 540px) {
  .popup-container {
    width: 315px !important;
    top: 55%;
  }
}

@media screen and (min-width: 280px) and (max-width: 280px) {
  .popup-container {
    width: 265px !important;
  }
}
