.footer-main {
  background-color: white;
}

.footer-main .circular-arrow {
  height: 22px;
  width: 22px;
  border: 2px solid #c0c1c3;
  border-radius: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-main .footer-btn {
  background-color: white;
  color: #343131;
  border-radius: 10px;
  border: none;
  max-width: 220px;
  width: 100%;
  padding: 8px;
  border: 1px solid #e77817;
  font-weight: bold;
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1);
}

.footer-main .footer-btn:hover {
  background-color: #ffc192;
  color: #343131;
  border: none;
  animation: rotate624 0.7s ease-in-out both;
}

@keyframes rotate624 {
  0% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }

  25% {
    transform: rotate(3deg) translate3d(0, 0, 0);
  }

  50% {
    transform: rotate(-3deg) translate3d(0, 0, 0);
  }

  75% {
    transform: rotate(1deg) translate3d(0, 0, 0);
  }

  100% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }
}
