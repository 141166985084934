@font-face {
  font-family: "Montserrat-Bold";
  src: local("Montserrat-Bold"),
    url("./assets/fonts/Montserrat-Bold/Montserrat-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-Medium";
  src: local("Montserrat-Medium"),
    url("./assets/fonts/Montserrat-Medium/Montserrat-Medium.ttf")
      format("truetype");
}
@font-face {
  font-family: "Montserrat-Regular";
  src: local("Montserrat-Regular"),
    url("./assets/fonts/Montserrat-Regular/Montserrat-Regular.ttf")
      format("truetype");
}
@font-face {
  font-family: "Montserrat-SemiBold";
  src: local("Montserrat-SemiBold"),
    url("./assets/fonts/Montserrat-SemiBold/Montserrat-SemiBold.ttf")
      format("truetype");
}
