.hero-main {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  .hero-main .hero-heading {
    font-size: 35px;
  }
}

.hero-main .hero-shadow {
  background: linear-gradient(rgba(45, 52, 54, 0.4), rgba(45, 52, 54, 1));
  border-radius: 20px;
  box-shadow: 0 8px 32px 0 rgba(45, 52, 54, 1);
}

.hero-main .hero-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.hero-main .hero-btns .hero-white-btn {
  background-color: white;
  color: black;
  border-radius: 10px;
  border: none;
  max-width: 210px;
  width: 100%;
  padding: 8px;
  border: 1px solid #ffc192;
}

.hero-main .hero-btns .hero-white-btn:hover {
  background-color: #ffc192;
  color: black;
  border-radius: 10px;
  border: none;
  max-width: 210px;
  width: 100%;
  padding: 8px;
  border: 1px solid #ffc192;
}

.hero-main .hero-btns .hero-white-btn2 {
  background-color: #ffc192;
  color: black;
  border-radius: 10px;
  border: 2px solid #e77817;
  /* max-width: 250px; */
  max-width: 236px;
  width: 100%;
  padding: 8px;
}

@media screen and (max-width: 1023px) {
  .hero-main .hero-inner {
    justify-content: center;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .hero-main .hero-btns .hero-white-btn,
  .hero-main .hero-btns .hero-white-btn2 {
    max-width: 220px;
    font-size: 14px;
  }
}

@media screen and (max-width: 540px) {
  .hero-main .hero-btns {
    display: flex;
    flex-direction: column;
  }

  .hero-main .hero-btns .hero-white-btn {
    max-width: 200px;
  }
  .hero-main .hero-btns .hero-white-btn2 {
    max-width: 270px;
  }

  .hero-main .hero-btns .hero-white-btn2 {
    margin: 10px 0;
  }

  /* --------------------- */
  .hero-main {
    min-height: 650px;
  }

  .hero-main .hero-heading {
    font-size: 26px;
  }
}
