@media screen and (min-width: 1024px) {
  .testimonial-main .testimonial-inner {
    margin: 32px 10px;
  }
}

@media screen and (max-width: 920px) {
  .testimonial-main .testimonial-inner {
    margin: 33px 57px;
  }
}

@media screen and (max-width: 540px) {
  .testimonial-main .testimonial-inner {
    margin: 20px 33px;
  }

  .testimonial-main .testimonial-inner .testimonial-left {
    position: absolute;
    top: -18px !important;
    left: -16px !important;
    height: 50px !important;
    width: 50px !important;
  }

  .testimonial-main .testimonial-inner .testimonial-user {
    position: absolute;
    bottom: -28px;
    left: -50px !important;
    height: 100px;
    width: 120px;
  }

  .testimonial-main .testimonial-inner .testimonial-right {
    position: absolute;
    bottom: -22px;
    right: -24px;
    height: 50px;
    width: 50px;
  }

  .testimonial-main .slick-dots li.slick-active button:before {
    font-size: 12px !important;
  }

  .testimonial-main .slick-dots li button:before {
    font-size: 12px !important;
  }
}

.testimonial-main .testimonial-inner {
  background-color: #343131;
  border-radius: 20px;
}

.testimonial-main .slick-slider {
  overflow: hidden !important;
}

.testimonial-main .testimonial-inner .testimonial-left {
  position: absolute;
  top: -32px;
  left: -29px;
}

.testimonial-main .testimonial-inner .testimonial-user {
  position: absolute;
  bottom: -32px;
  left: -60px;
}

.testimonial-main .testimonial-inner .testimonial-right {
  position: absolute;
  bottom: -32px;
  right: -29px;
}

.testimonial-main .slick-prev:before {
  content: "" !important;
}

.testimonial-main .slick-prev {
  background-image: url("../assets/images/left-arrow.png") !important;
  background-repeat: no-repeat !important;
  height: 45px !important;
  width: 60px !important;
  left: 0px !important;
}

.testimonial-main .slick-next:before {
  content: "" !important;
}

.testimonial-main .slick-next {
  background-image: url("../assets/images/right-arrow.png") !important;
  background-repeat: no-repeat !important;
  height: 45px !important;
  width: 60px !important;
}

.slick-dots {
  bottom: 10px !important;
}

.testimonial-main .slick-dots li.slick-active button:before {
  color: #e77817;
  font-size: 16px;
  margin: 10px 0;
}

.testimonial-main .slick-dots li button:before {
  color: #ffc192;
  font-size: 16px;
  margin: 10px 0;
  border: none;
}
