.text-theme {
  color: #e77817;
}

.text-theme-light {
  color: #ffc192;
}

.text-theme-dark {
  color: #343131;
}

.text-light-white {
  color: #c0c1c3;
}

.global-white-btn {
  background-color: white;
  color: black;
  border-radius: 10px;
  border: none;
  max-width: 220px;
  width: 100%;
  padding: 8px;
  border: 1px solid #ffc192;
}

.global-light-btn {
  background-color: #ffc192;
  color: black;
  border-radius: 10px;
  border: 2px solid #e77817;
  max-width: 260px;
  width: 100%;
  padding: 8px;
}

.visibility-hidden {
  visibility: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

@media screen and (min-width: 1024px) {
  .width-75 {
    width: 75%;
  }
}

/* -----Pages Styling---- */
.home-page {
  background: linear-gradient(
    to bottom,
    #180314 0%,
    #420047 19%,
    #7a0162 39%,
    #5c0043 58%,
    #59002e 78%,
    #520030 89%,
    #a70096 100%
  );
  overflow-x: hidden;
  /* background-image: url("../src/assets/images/homeGradient.png");
  background-repeat: repeat;
  background-position: top left; */
  /* background-attachment: fixed; */
}

.about-page {
  /* background: linear-gradient(
    to top,
    #002a50 0%,
    #d9ad6b 16%,
    #000024 28%,
    #002e58 48%,
    #002e58 65%,
    #d9ad6b 79%,
    #022f56 100%
  ); */

  background-image: url("../src/assets/images/aboutGradient.jpg");
  background-repeat: repeat;
  background-position: bottom;
  background-size: cover;
}

.branding-page {
  background: linear-gradient(
    to bottom,
    #09080d 0%,
    #26190e 56%,
    #3a2000 65%,
    #2222a8 79%,
    #00208f 100%
  );
  /* 
  background-image: url("../src/assets/images/brandingGradient.jpg");
  background-repeat: repeat;
  background-position: center;
  background-size: cover;
   background-attachment: fixed; */
}
.development-page {
  background: linear-gradient(
    to top,
    #19191b 0%,
    #dc4107 28%,
    #0d0b0e 52%,
    #5b1c00 75%,
    #040000 100%
  );

  /* background-image: url("../src/assets/images/developmentGradient.jpg");
  background-repeat: repeat;
  background-position: top left;
  background-size: cover; */
}

.marketing-page {
  background: linear-gradient(
    to bottom,
    #111214 0%,
    #4b2209 100%,
    #f47226 100%,
    #fb8c44 100%
  );

  /* background-image: url("../src/assets/images/digitalMarketingGradient.jpg");
  background-repeat: repeat;
  background-position: top;
  background-size: cover; */
}

.terms-and-condition-page {
  background-image: url("../src/assets/images/termAndConditionGradient.jpg");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}

@media screen and (max-width: 540px) {
  .terms-and-condition-page {
    background-image: none;
    background: linear-gradient(
      to bottom,
      #000000 0%,
      #000000 23%,
      #9d3502 77%,
      #0d0500 80%,
      #4d1903 91%
    );
  }
}

.privacy-policy {
  background-image: url("../src/assets/images/privacyPolicyGradient.jpg");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  /* 
  background: linear-gradient(
    to bottom,
    #000000 10%,
    #000000 51%,
    #0000bd 79%,
    #000000 86%,
    #000000 96%
  ); */
}

@media screen and (max-width: 540px) {
  .privacy-policy {
    background-image: none;
    background: linear-gradient(
      to bottom,
      #000000 10%,
      #000000 51%,
      #0000bd 79%,
      #000000 86%,
      #000000 96%
    );
  }
}

.thank-you-page {
  background-image: url("../src/assets/images/ThankYou.jpg");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  min-height: 100vh;
}

.thank-you-page .thank-img {
  height: 100%;
  width: 100%;
  max-height: 300px;
  max-width: 300px;
}

/* ----Fonts------0 */

.font-face-Montserrat-Bold {
  font-family: "Montserrat-Bold";
}
.font-face-Montserrat-Regular {
  font-family: "Montserrat-Regular";
}
.font-face-Montserrat-Medium {
  font-family: "Montserrat-Medium";
}
.font-face-Montserrat-SemiBold {
  font-family: "Montserrat-SemiBold";
}

.upndown {
  animation: upndown 2s infinite alternate;
}

@keyframes upndown {
  from {
    transform: translateY(5px);
  }
  to {
    transform: translateY(-70px);
  }
}

@media screen and (max-width: 540px) {
  .upndown {
    animation: upndown-small 2s infinite alternate;
  }

  @keyframes upndown-small {
    from {
      transform: translateY(1px);
    }
    to {
      transform: translateY(-50px);
    }
  }
}

/* ---ScrollBar---- */

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: #2e2e30;
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background: #202020;
}

::-webkit-scrollbar-thumb {
  background-color: #ffc192;
  border-radius: 6px;
}

@media screen and (max-width: 540px) {
  .phone-icon {
    content: url("./assets/images/phoneMobileIcon.svg");
  }
  .whatsApp-icon {
    content: url("./assets/images/whatsAppMobile.svg");
  }
}
