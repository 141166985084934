.portfolio-main {
  overflow-x: hidden !important;
}
.portfolio-main .nav-tabs {
  border-bottom: none !important;
  justify-content: center !important;
}

.portfolio-main .nav-link {
  color: white !important;
  transition: none !important;
}

.portfolio-main .nav-link:hover {
  border: none !important;
  outline: none !important;
  transition: none !important;
}

.portfolio-main .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #ffc192 !important;
  background-color: transparent !important;
  border: none !important;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  --bs-modal-bg: transparent !important;
  --bs-modal-border-color: none !important;
}

.modal-header {
  border: none !important;
  background-color: transparent !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.modal-backdrop {
  --bs-backdrop-opacity: 0.8 !important;
}

.model-image {
  transition: 0.3s !important;
}

.modal-content {
  border-radius: 0 !important;
  margin: auto;
  display: block;
  width: 100%;
  max-width: 500px;
  animation-name: zoom !important;
  animation-duration: 0.6s !important;
}

.modal-body {
  padding: 0px !important;
  margin: auto !important;
}

@media screen and (min-width: 768px) {
  .modal .prev-btn {
    cursor: pointer;
    position: absolute;
    top: 45%;
    left: -15%;
  }
  .modal .next-btn {
    cursor: pointer;
    position: absolute;
    top: 45%;
    right: -15%;
  }
}

@media screen and (max-width: 540px) {
  .modal .prev-btn {
    display: none;
  }
  .modal .next-btn {
    display: none;
  }
}

@media screen and (min-width: 540px) {
  .modal .res-mobil-btns {
    display: none !important;
  }
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* .w-100 {
  transition: transform 0.4s ease;
} */

/* .w-100:hover {
  transform: scale(1.1);
  border-radius: 10px;
} */
