.about-main .social-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  flex-wrap: wrap;
}

.about-main .social-buttons .frame-title {
  font-family: "Montserrat-Medium";
}

@media screen and (min-width: 540px) {
  .about-main .about-marque {
    display: none !important;
  }
  .about-main .about-lg {
    display: block !important;
  }
}
@media screen and (max-width: 540px) {
  .about-main .about-marque {
    display: block !important;
  }
  .about-main .about-lg {
    display: none !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .about-main .social-buttons {
    width: 100%;
    justify-content: space-between !important;
  }
  .about-main .social-buttons .frame-btn {
    max-width: 110px !important;
    padding: 5px 0 !important;
  }
  .about-main .social-buttons .frame-title {
    font-size: 12px;
  }
}

@media screen and (max-width: 540px) {
  .about-main .social-buttons {
    width: 100%;
    justify-content: space-around !important;
  }
  .about-main .social-buttons .frame-btn {
    padding: 5px 5px !important;
  }
}

.about-main .social-buttons .frame-btn {
  background-color: #ffc192;
  padding: 15px 0;
  width: 100%;
  max-width: 150px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: 10px 0;
}
.about-main .social-buttons .frame-btn .frame-title {
  color: #343131;
}
