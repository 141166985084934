.contact-main .border-right {
  border-right: 1px solid #ffc192;
}

.contact-main .name-input,
.contact-main .email-input,
.contact-main .phone-input,
.contact-main .subject-input {
  flex: 0.48;
  transition: transform 0.3s ease;
}

.contact-main textarea {
  margin-top: 30px;
}

.contact-main .name-input,
.contact-main .email-input,
.contact-main .phone-input,
.contact-main .subject-input,
.contact-main textarea {
  outline: none;
  border: 1px solid #ffc192;
  background-color: #f9fafc;
  padding: 8px;
  border-radius: 5px;
  font-size: 14px;
  transition: transform 0.3s ease;
}

.contact-main .name-input::placeholder,
.contact-main .email-input::placeholder,
.contact-main .phone-input::placeholder,
.contact-main .subject-input::placeholder,
.contact-main textarea::placeholder {
  color: #2c2d30;
}

.contact-main .name-input:hover,
.contact-main .email-input:hover,
.contact-main .phone-input:hover,
.contact-main .subject-input:hover,
.contact-main textarea:hover {
  transform: scale(1.05); /* Scales the input fields and textarea by 5% */
}

.contact-main #formCheckbox {
  accent-color: white;
}

.contact-main .contact-btn {
  color: #343131;
  background-color: white;
  border-radius: 12px;
  width: 100%;
  max-width: 350px;
  padding: 8px;
  box-sizing: border-box;
  border: none;
}

.contact-main .tick-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .contact-main .input-views {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
  }
}

@media screen and (max-width: 540px) {
  .contact-main .name-input,
  .contact-main .email-input,
  .contact-main .phone-input,
  .contact-main .subject-input {
    flex: 1;
    margin-top: 30px;
  }

  .contact-main .input-views {
    display: flex;
    flex-direction: column;
  }

  .contact-main .checkbox-view {
    justify-content: center;
  }

  .contact-main .border-right {
    border: none;
  }
}

.contact-main .iconsHover:hover {
  transform: scale(1.5);
  transition: transform 0.4s ease-in-out;
}

.contact-main .contact-btn:hover {
  background-color: transparent;
  border: 1px solid #ffc192;
  color: white;
  animation: rotate624 0.7s ease-in-out both;
}

@keyframes rotate624 {
  0% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }

  25% {
    transform: rotate(3deg) translate3d(0, 0, 0);
  }

  50% {
    transform: rotate(-3deg) translate3d(0, 0, 0);
  }

  75% {
    transform: rotate(1deg) translate3d(0, 0, 0);
  }

  100% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }
}
