.custom-packages .package-btn {
  border: 1px solid white;
  background-color: transparent;
  color: white;
  border-radius: 5px;
  width: 100%;
  max-width: 175px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1);
}
.custom-packages .inner {
  background-color: #202020;
  border-radius: 10px;
  border: 1px solid #2e2e30;
}

.custom-packages .inner .package-scroll {
  min-height: 300px;
  overflow-y: auto;
  padding: 5px 0;
  margin: 5px 0;
}
.custom-packages .inner:hover .package-btn .arrow-icon {
  content: url("../assets/images/rightArrowBlack.svg");
}

.custom-packages .inner:hover {
  background-color: #2e2e30;
  border: 1px solid transparent;
}
.custom-packages .inner:hover .package-btn {
  background-color: #ffc192;
  color: #343131;
  border: 1px solid #2e2e30;
  animation: rotate624 0.7s ease-in-out both;
}

/* .custom-packages .inner .package-scroll::-webkit-scrollbar {
  width: 4px;
}

.custom-packages .inner .package-scroll::-webkit-scrollbar-track {
  background: #2e2e30;
  border-radius: 6px;
}

.custom-packages .inner:hover .package-scroll::-webkit-scrollbar-track {
  background: #202020;
}

.custom-packages .inner .package-scroll::-webkit-scrollbar-thumb {
  background-color: #ffc192;
  border-radius: 6px;
} */

@keyframes rotate624 {
  0% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }

  25% {
    transform: rotate(3deg) translate3d(0, 0, 0);
  }

  50% {
    transform: rotate(-3deg) translate3d(0, 0, 0);
  }

  75% {
    transform: rotate(1deg) translate3d(0, 0, 0);
  }

  100% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }
}

.custom-packages .nav-tabs {
  border-bottom: none !important;
  justify-content: center !important;
}

.custom-packages .nav-link {
  color: white !important;
  transition: none !important;
}

.custom-packages .nav-link:hover {
  border: none !important;
  outline: none !important;
  transition: none !important;
}

.custom-packages .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #ffc192 !important;
  background-color: transparent !important;
  border: none !important;
}
