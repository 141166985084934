.custom-services .service-inner {
  background-color: #3dadea;
  position: relative;
  border-radius: 20px;
}

.custom-services .service-inner .service-image {
  position: relative;
  left: -5px;
  bottom: 5px;
  width: 100%;
  height: auto;
}

.custom-services .service-main .service-heading,
.custom-services .service-main .service-description {
  color: white;
}

.custom-services .service-main:hover .service-heading {
  color: #ffc192;
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}

.custom-services .service-main:hover .service-inner {
  background-color: #ffc192;
}

.custom-services .service-main:hover .service-circle .tick-icon {
  content: url("../assets/images/serciceArrow.svg") !important;
  cursor: pointer;
}

@media screen and (max-width: 320px) {
  .custom-services .service-main .service-heading {
    font-size: 19px;
  }
}
